<template>
    <div class="main">
        <p>
            <router-link to="home">1.首页</router-link>
        </p>
        <p>
            <router-link to="productDetail">2.产品详情</router-link>
        </p>
        <p>
            <router-link to="policyHolderManagement">3.常用投保人管理</router-link>
        </p>
        <p>
            <router-link to="infoForm">4.信息填写</router-link>
        </p>
        <p>
            <router-link to="confirmInfo">5.信息确认</router-link>
        </p>
        <p>
            <router-link to="policyDetails">6.保单详情页</router-link>
        </p>
        <p>
            <router-link to="myOrder">7.我的保单</router-link>
        </p>
        <p>
            <router-link to="electronicInvoice">8.电子发票</router-link>
        </p>
        <p>
            <router-link to="writeInformation">9.身份证识别</router-link>
        </p>
        <p>
            <router-link to="addManagement">10.添加常用投保人</router-link>
        </p>
        <p>
            <router-link to="electronicBill">11.电子发票</router-link>
        </p>
        <p>
            <router-link to="insuranceDetails">12.保险详情</router-link>
        </p>
        <p>
            <router-link to="myOrders">13.我的保单</router-link>
        </p>
        <p>
            <router-link to="modifyInsued">14.批改被保人</router-link>
        </p>
        <p>
            <router-link to="login">14.批改被保人</router-link>
        </p>
    </div>
</template>

<script>
export default {};
</script>

<style>
.main {
    padding: 0.3rem;
    font-size: 0.35rem;
    font-weight: bold;
}
p {
    padding: 0.15rem;
    margin: 0;
}
</style>